<template>
  <section>
    <div v-if="loading">
      <b-skeleton animation="fade" width="100%" />
      <b-skeleton animation="fade" width="100%" />
      <b-skeleton animation="fade" width="100%" />
    </div>
    <b-table-simple id="guides-table" responsive borderless fixed v-else>
      <thead>
        <tr v-for="(row, index) in rows" :key="index">
          <th
            v-show="index === 0"
            v-for="header in headers"
            :key="header.label"
            :id="header.label"
          >
            <CheckInput
              id="select-all-guides"
              :value="hasSelected"
              @change="handleCheckAll(!hasSelected)"
              :disabled="!hasSelected && !hasFilteredByHealthPlan"
              :tooltip="
                !hasSelected && !hasFilteredByHealthPlan
                  ? 'Para habilitar a seleção de todas as guias, adicione o filtro de convênio.'
                  : ''
              "
              v-if="header.label === 'checkbox' && rows.length"
            />
            <b-row
              class="cursor-pointer"
              @click="handleOrder(header.column)"
              v-else-if="header.column"
            >
              <span>{{ header.label }}</span>
              <OrdenateIconHandler
                :position="order[header.column]"
                class="ml-1"
              />
            </b-row>
            <b-row v-else>
              <div>{{ header.label }}</div>
            </b-row>
          </th>
        </tr>
      </thead>
      <tbody v-for="(row, index) in rows" :key="index">
        <tr v-if="!hideRows">
          <td>
            <CheckInput
              :value="selectedGuides.map(item => item.id).includes(row.id)"
              @change="selectGuidesCheck(row)"
              :disabled="disableGuidesCheckbox(row)"
              :tooltip="rowCheckTooltipText(row)"
            />
          </td>
          <td>
            <span
              class="situation-tag"
              :style="`background: ${colors[row.situation]}`"
            >
              <section class="text-tag">
                {{ row.situation }}
              </section>
            </span>
          </td>
          <td>{{ parseDate(row.created_at) }}</td>
          <td>
            <Ellipsis>{{ row.patient?.name }}</Ellipsis>
            {{ row.provider_guide ?? '-' }}
          </td>
          <td>
            {{
              row.invoicing_tiss_setting &&
              row.invoicing_tiss_setting.clinic_health_plan
                ? row.invoicing_tiss_setting.clinic_health_plan.health_plan
                    .fantasy_name
                : ''
            }}
          </td>
          <td>
            <div>
              {{ row.tiss_invoice ? `Fat Nº ${row.tiss_invoice.number}` : '-' }}
            </div>
            <div v-if="row.tiss_batch">
              {{ `Lot Nº ${row.tiss_batch.number}` }}
            </div>
          </td>
          <td>{{ getTypes(row.guide_type) }}</td>
          <td>
            <span id="tissGuideTotal">
              {{ getTissGuideTotal(row) }}
            </span>
            <span
              v-if="getUnformattedTissGuideTotal(row) === 0"
              :id="`helpIcon_${row.id}_${index}`"
              class="icon-box"
              ><HelpCircle class="help-icon" />
              <b-tooltip
                :target="`helpIcon_${row.id}_${index}`"
                triggers="hover"
                placement="bottom"
              >
                Preencha os códigos dos procedimentos na guia para visualizar o
                valor da guia
              </b-tooltip>
            </span>
          </td>
          <td>{{ getTissGuidePaidTotal(row) }}</td>
          <td>
            <label class="checkbox ml-2">
              <input
                type="checkbox"
                v-model="row.conferred"
                @change="changeCheck(row)"
              />
              <span class="checkmark">
                <CheckIcon />
              </span>
            </label>
          </td>
          <td>
            <div class="actions">
              <b-button
                v-b-toggle="`collapse-${row.id}-${index}`"
                class="toggle-btn"
                @click="rotateIcon"
                :disabled="!row?.items?.length"
              >
                <ChevronDown class="icon" />
              </b-button>
              <Icon v-if="!headers.map(el => el.label).includes('Arquivo')">
                <File
                  class="icon-gray"
                  @click="openHistoric(row.id)"
                  v-b-tooltip.hover title="Histórico de ações"
                />
              </Icon>
              <Icon v-if="!headers.map(el => el.label).includes('Arquivo')">
                <Clip
                  class="icon-gray"
                  @click="row.files_count ? openDocumentListModal(row) : openDocumentModal(row)"
                  v-b-tooltip.hover 
                  :title="row.files_count ? 'Abrir arquivos' : 'Anexar arquivos'"
                />
              </Icon>
              <Icon v-if="getTypes(row.guide_type) === 'SP/SADT' && url.includes('/faturamento-tiss/relatorio-de-guias')">
                <Copy
                  class="icon-gray"
                  @click="duplicateSPSADTGuide(row?.id)"
                  v-b-tooltip.hover 
                  title="Duplicar guia"
                />
              </Icon>
              <Icon
              placement="left"
              :tooltip="
                row.situation !== 'Finalizada'
                  ? 'Editar e ver detalhes'
                  : 'Ver Detalhes'
              "
            >
              <Edit class="icon" @click="getGuide(row)" />
            </Icon>
              <Icon>
                <Trash
                  class="icon trash"
                  @click="!row.tiss_batch ? onDelete(row) : null"
                  v-b-tooltip.hover
                  :title="
                    !row.tiss_batch
                      ? 'Remover guia'
                      : 'Não é possível excluir guia com lote'
                  "
                  v-can="'FatTissRelGui2'"
                />
              </Icon>
              <!-- <div
                class="options"
                @mouseover="
                  () => {
                    if (index === rows.length - 1) openedMenu = true
                  }
                "
                @mouseout="openedMenu = false"
              >
                <MoreVertical class="more-icon" />
                <div class="menu">
                  <b-button
                    v-if="canRemove"
                    variant="link remove mb-0"
                    @click="removeGuide(row)"
                  >
                    Remover Guia
                  </b-button>
                </div>
              </div> -->
            </div>
          </td>
        </tr>

        <tr class="collapsable-row">
          <td colspan="10">
            <b-collapse :id="`collapse-${row.id}-${index}`" class="collapse">
              <b-table-simple class="collapse-table mt-3">
                <thead>
                  <tr>
                    <th v-for="header in subHeaders" :key="header" scope="col">
                      {{ header }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(collapseRow, index) in row.items" :key="index">
                    <td>
                      <span
                        class="situation-tag"
                        :style="`background: ${colors[collapseRow.status]}`"
                      >
                        {{ collapseRow.status || 'Pendente' }}
                      </span>
                    </td>
                    <td>{{ getTypes(collapseRow.guide_type) }}</td>
                    <td>{{ parseDate(collapseRow.updated_date) }}</td>
                    <td>{{ collapseRow.table_name }}</td>
                    <td>{{ collapseRow?.item?.code ?? '' }}</td>
                    <td>{{ collapseRow.description ?? collapseRow.item?.name}}</td>
                    <td>{{ parseNumberToMoney(collapseRow.unitary_value * collapseRow.quantity) }}</td>
                    <td>{{ parseNumberToMoney(collapseRow.value_paid) }}</td>
                  </tr>
                </tbody>
              </b-table-simple>
            </b-collapse>
          </td>
        </tr>
      </tbody>
      <tr>
        <td :class="{ spacing: openedMenu }"></td>
      </tr>
    </b-table-simple>

    <noFilterResults
      v-if="!rows?.length && !loading"
      message="Os filtros aplicados não geraram nenhum resultado."
    />
  </section>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { getCurrentClinic } from '@/utils/localStorageManager'
import {
  getTypes,
  parseItemType
} from '@/modules/tiss/manageGuides/utils/guideHelper'
import {
  TissGuide,
  TissInvoice
} from '@/modules/tiss/manageGuides/utils/statuses'

export default {
  name: 'GuideReportTable',
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    noFilterResults: () => import('@/components/General/noFilterResults'),
    CheckInput: () => import('@/components/General/Check'),
    CheckIcon: () => import('@/assets/icons/check.svg'),
    Trash: () => import('@/assets/icons/trash.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    Icon: () => import('@/components/General/Icon'),
    HelpCircle: () => import('@/assets/icons/help-circle.svg'),
    Ellipsis: () => import('@/components/General/Ellipsis'),
    OrdenateIconHandler: () =>
      import('@/components/General/OrdenateIconHandler.vue'),
    Clip: () => import('@/assets/icons/clip2.svg'),
    Copy: () => import('@/assets/icons/copy.svg'),
    File: () => import('@/assets/icons/fileHistory.svg')
  },
  props: {
    rows: Array,
    getGuide: Function,
    openHistoric: Function,
    openDocumentModal: Function,
    openDocumentListModal: Function,
    status: String,
    addAndOpenGuide: Function,
    loading: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    canRemove: {
      type: Boolean,
      default: false
    },
    hasSavedInvoiceId: {
      type: Boolean,
      default: false
    },
    filters: Object,
    allSelectableGuides: Array
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      headers: [
        { label: 'checkbox' },
        { label: 'Situação' },
        { label: 'Data', column: 'created_at' },
        { label: 'Paciente/Guia', column: 'patient_name' },
        { label: 'Convênio' },
        { label: 'Fatura/Lote' },
        { label: 'Tipo de guia' },
        { label: 'Faturado' },
        { label: 'Recebido' },
        { label: 'Conferido' },
        { label: '' }
      ],
      subHeaders: [
        'Status',
        'Guia',
        'Atualização',
        'Tabela',
        'Cód. Item 3',
        'Descrição',
        'Valor faturado',
        'Valor pago'
      ],
      selectedGuides: [],
      watchAllSelected: true,
      tissGuide: {},
      colors: {
        [TissGuide.GUIDE_SITUATION_OPEN]: '#FCDEC2',
        [TissGuide.GUIDE_SITUATION_IN_PREPARATION]: '#FCDEC2',
        [TissGuide.GUIDE_SITUATION_PAYED]: 'var(--states-success-soft-green)',
        [TissGuide.GUIDE_SITUATION_PARTIALLY_PAID]: '#E7FFDB',
        [TissGuide.GUIDE_SITUATION_GLOSSED]: '#FED2CE',
        [TissGuide.GUIDE_SITUATION_AUTHORIZED]: '#E7ECFE;'
      },
      selectedType: null,
      hideRows: false,
      openedMenu: false,
      maxGuidesPerBatch: null,
      order: { created_at: null, patient_name: null },
      hasSelected: false
    }
  },
  computed: {
    url() {
      return window.location.href
    },
    hasFilteredByHealthPlan() {
      return this.filters?.healthPlan !== null
    }
  },
  methods: {
    getTypes,
    parseItemType,
    parseNumberToMoney,
    parseDate(date) {
      return this.moment(date).format('DD/MM/YYYY')
    },
    getTissGuideTotal(guide) {
      const total = this.getUnformattedTissGuideTotal(guide)
      return parseNumberToMoney(total)
    },
    getUnformattedTissGuideTotal(guide) {
      const total = guide.items.reduce((acc, item) => {
        acc += item.total_value <= 0 ? (item.unitary_value * item.quantity) : item.total_value
        return acc
      }, 0)
      return total
    },
    getTissGuidePaidTotal(guide) {
      const total = guide.items.reduce((acc, item) => {
        acc += item.value_paid
        return acc
      }, 0)
      return parseNumberToMoney(total)
    },
    rowCheckTooltipText(guide) {
      if (this.hasOtherHealthPlan(guide)) {
        return 'Esta guia foi gerada por um convênio diferente das outras guias selecionadas'
      }
      if (!this.canEditInvoice(guide)) {
        return 'Esta guia está vinculada a uma fatura fechada'
      }
      return ''
    },
    hasOtherHealthPlan(guide) {
      const healthPlanId =
        guide.invoicing_tiss_setting?.clinic_health_plan?.health_plan.id

      return (
        this.selectedGuides.length > 0 &&
        this.selectedGuides[0].invoicing_tiss_setting?.clinic_health_plan
          ?.health_plan.id !== healthPlanId
      )
    },
    canEditInvoice(guide) {
      return (
        !guide.tiss_invoice_id ||
        guide.tiss_invoice?.status === TissInvoice.INVOICE_STATUS_OPEN
      )
    },
    disableGuidesCheckbox(guide) {
      const hasOtherHealthPlan = this.hasOtherHealthPlan(guide)
      const canEditInvoice = this.canEditInvoice(guide)

      return hasOtherHealthPlan || !canEditInvoice
    },
    unselectAllGuidesChecks() {
      this.selectedGuides = []
      this.$emit('selected-guides', this.selectedGuides)
    },
    selectGuidesCheck(row) {
      const index = this.selectedGuides.map(item => item.id).indexOf(row.id)
      if (index !== -1) {
        this.selectedGuides.splice(index, 1)
      } else {
        this.selectedGuides.push(row)
      }
      this.$emit('selected-guides', this.selectedGuides)
    },
    handleCheckbox(value) {
      if (!value && this.selectAll) {
        this.watchAllSelected = false
        this.selectAll = false
        setTimeout(() => {
          this.watchAllSelected = true
        }, '1000')
      }
    },
    handleCheckAll(value) {
      if (value) {
        this.allSelectableGuides.forEach(row => {
          const canEditInvoice =
            !row.tiss_invoice_id ||
            row.tiss_invoice?.status === TissInvoice.INVOICE_STATUS_OPEN
          if (canEditInvoice) {
            this.selectGuidesCheck(row)
          }
        })
        return
      }
      this.unselectAllGuidesChecks()
    },
    async changeCheck(value) {
      const isLoading = this.$loading.show()
      try {
        await this.api.updateNewTissGuide(value.id, {
          conferred: value.conferred,
          clinic_id: this.clinic.id
        })
        if (value.conferred) {
          this.$toast.success('Guia conferida com sucesso!')
        } else {
          this.$toast.success('Guia desmarcada com sucesso!')
        }
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    rotateIcon($event) {
      const icon = $event.target
      icon.classList.toggle('rotate')
    },
    removeGuide(row) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente remover a Guia <span>${getTypes(
          row.guide_type
        )} - Nº ${row.provider_guide} do paciente ${
          row.patient.name
        }</span> da fatura atual?</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(res => {
        if (res.isConfirmed) {
          this.$emit('onRemoveGuide', row)
        }
      })
    },
    onDelete(row) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente excluir a Guia <span>${getTypes(
          row.guide_type
        )} - ${row.patient.name}/Nº${
          row.provider_guide
        }</span>? Esta ação não poderá ser desfeita.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(res => {
        if (res.isConfirmed) {
          this.$emit('onDeleteGuide', row)
        }
      })
    },
    async duplicateSPSADTGuide(id) {
      const isLoading = this.$loading.show()
      try {
        const { data } = await this.api.duplicateSPSADTGuide(id)
        this.addAndOpenGuide(data)
        this.$toast.success('Guia duplicada com sucesso!')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    handleOrder(key) {
      if (!this.order[key]) {
        this.order[key] = 'asc'
      } else if (this.order[key] === 'asc') {
        this.order[key] = 'desc'
      } else if (this.order[key] === 'desc') {
        this.order[key] = null
      }

      this.$emit('onOrdenate', this.order)
    }
  },
  watch: {
    hasSavedInvoiceId(value) {
      if (value) {
        this.unselectAllGuidesChecks()
        this.$emit('unselectedChecks')
      }
    },
    selectedGuides(value) {
      this.hasSelected = value.length > 0
    }
  }
}
</script>

<style lang="scss" src="@/assets/scss/table.scss" />

<style lang="scss" scoped>
#guides-table {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  table-layout: fixed;
  margin: 5px 0;

  thead {
    font-weight: 600;
    color: var(--dark-blue);

    tr {
      background-color: var(--neutral-100);
    }
  }

  tbody {
    color: var(--type-active);
    font-weight: 600;
  }

  .spacing {
    height: 120px;
  }

  td {
    max-width: 350px !important;
  }

  td,
  th {
    @media (max-width: 1330px) {
      font-size: 12px;
    }
    .cursor-pointer {
      cursor: pointer;
    }

    .help-icon {
      width: 14px;
      margin-bottom: 4px;
    }
    .situation-tag {
      width: 100%;
      border-radius: 16px;
      padding: 5px 10px;
      background-color: var(--neutral-300);
      display: inline-block;
      text-align: center;
    }

    .text-tag {
      text-overflow: ellipsis !important;
      overflow: hidden !important;
      white-space: nowrap !important;
    }

    .toggle-btn {
      background: var(--blue-100);
      border: none;
      padding: 5px !important;

      .icon {
        width: 25px;
        transition: all 500ms;
      }
    }

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .multi-content {
      display: flex;
      align-items: center;

      .multi-content-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .text-container {
          max-width: 10ch !important;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .popover-element-container {
        visibility: hidden !important;
        position: relative;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid var(--neutral-300);
        background-color: var(--neutral-000);
        // margin-top: 20px;
      }
    }

    .multi-content:hover {
      .popover-element-container {
        visibility: visible !important;
        p {
          max-width: 20ch !important;
        }
      }
    }

    .checkbox {
      margin-left: 25px !important;
    }

    .actions {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }

    .options {
      cursor: pointer;
      width: 24px;
      height: 28px;
      position: relative;
      display: inline-block;

      .more-icon {
        width: 24px;
        height: 24px;
        stroke: var(--neutral-500);
        transition: all 0.5s;
        cursor: pointer;
      }

      .menu {
        z-index: 10;
        min-width: 180px;
        position: absolute;
        top: 28px;
        right: 0;
        background: var(--neutral-000);
        border: 1px solid var(--neutral-200);
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
          0px 4px 10px -8px rgba(12, 29, 89, 0.2);
        border-radius: 8px;
        padding: 0;
        opacity: 0;
        transition: all 0.5s;
        flex-direction: column;
        align-items: start;
        display: none;

        .mtb-12 {
          margin: 12px 0;
        }

        .btn {
          font-weight: 600;
          width: 100%;

          &.remove {
            color: var(--states-error);
          }

          &.btn-link {
            text-align: left;
            padding: 12px 18px !important;
          }
        }
      }

      &:hover {
        .more-icon {
          transform: rotate(90deg);
        }

        .menu {
          opacity: 1;
          display: flex;
          z-index: 10;
        }
      }
    }
  }

  .hide-btn {
    color: var(--blue-700);
    text-decoration: underline;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  td:last-child {
    overflow: visible;
  }

  .collapsable-row {
    td {
      padding: 0;
      border-top: none;
      border-bottom: 0.5px dotted var(--neutral-300);
    }

    .collapse-table {
      width: 100%;
      margin: 0 auto 20px auto;

      thead {
        tr {
          border-radius: 8px !important;
          background-color: var(--neutral-200);

          th {
            color: var(--dark-blue);
            font-weight: 600;
            font-size: 16px;
          }

          th:nth-child(1) {
            border-top-left-radius: 8px;
          }

          th:last-child {
            border-top-right-radius: 8px !important;
          }
        }
      }

      tbody {
        border: 1px dashed var(--neutral-200);

        tr {
          td {
            padding: 15px 5px;
          }

          td:nth-child(4) {
            max-width: 35ch;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .icon {
    width: 20px;
    height: 20px;
    cursor: pointer;

    &.trash {
      width: 20px;
      height: 20px;
      fill: none;
      stroke: var(--states-red-soft);
    }

    &.trash:hover {
      stroke: var(--states-error);
    }

  }

  .icon-gray {
    cursor: pointer;
    stroke: var(--neutral-100);
  }
}
.checkbox {
  margin: 0 0 25px;
}
.text-align-center {
  text-align: center;
}

.rotate {
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
</style>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--neutral-000);
  border-radius: 0 0 8px 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--neutral-300);
  border-radius: 100px;
  border: 3px solid var(--neutral-000);
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: var(--neutral-200);
}
</style>
